import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Plan } from '../models/plan.model';
import {environment} from "../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class SubscriptionService {
    private readonly apiUrl = environment.apiUrl;

    constructor(private http: HttpClient) {}

    getPlans(): Observable<Plan[]> {
        const headers = new HttpHeaders({
            'Accept-Language': 'pt-BR,pt;q=0.9,en-US;q=0.8,en;q=0.7',
            'accept': 'application/json'
        });

        return this.http.get<Plan[]>(this.apiUrl, { headers });
    }
}
