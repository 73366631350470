<div id="about" class="features-style-two pb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="feature-image">
                    <img src="assets/img/sass-landing/classmap2.png" alt="laptop">
                </div>
            </div>
            <div class="col-lg-6">
                <div class="feature-text">
                    <div class="section-title text-start">
                        <h2>Você poderá verificar, na prática, como tudo isso funciona.</h2>
                        <p>Chegou o momento de transformar a forma como você organiza e gerencia seus alunos. Não é mais necessário esperar para ter um sistema eficiente e sem complicações. Com as ferramentas certas, você pode ter total controle, melhorar a gestão e otimizar o seu tempo, tudo em poucos cliques. Não perca mais tempo com processos manuais ou confusos. A boa organização e gestão que você sempre desejou está ao seu alcance agora. Dê o próximo passo para uma rotina mais produtiva, eficiente e sem stress. Comece agora e sinta a diferença!</p>
                    </div>
                    <ul>
                        <li><i class="flaticon-checked"></i> Interface de usuário limpa</li>
                        <li><i class="flaticon-checked"></i> Fácil de usar</li>
                        <li><i class="flaticon-checked"></i> Melhor desempenho</li>
                        <li><i class="flaticon-checked"></i> Novas atualizações gratuitas</li>
                        <li><i class="flaticon-checked"></i> Armazenamento de dados forte e seguro</li>
                    </ul>
<!--                    <div class="feature-btn">-->
<!--                        <a routerLink="/sass-landing-two">Get Started</a>-->
<!--                    </div>-->
                    <div class="feature-btn">
                        <a [href]="getUrlSignup()" target="_blank" rel="noopener noreferrer">Comece Sua Avaliação Gratuita</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="featues-shape">
        <img src="assets/img/sass-landing/shapes/4.png" alt="shape">
    </div>
</div>
