<div *ngIf="plans && plans.length; else noPlans" class="plan-container">
    <div *ngFor="let plan of plans" class="plan-card">
        <h3>{{ plan.name }}</h3>
        <p>{{ plan.description }}</p>
        <p *ngIf="plan.maxStudents < 10000"><strong>Máximo de Alunos:</strong> {{ plan.maxStudents }}</p>
        <p *ngIf="plan.maxStudents >= 10000"><strong>Sem número máximo de alunos</strong></p>
        <div class="pricing">
            <div class="price-de" *ngIf="plan.promotionalSubscriptionMonths > 0">
                <small>De</small>
                <span class="original-price">
                    R$ {{ plan.subscriptionValue | currency: 'BRL':'symbol':'1.2-2':'pt-BR' }}
                </span>
            </div>
            <div class="price-por">
                <span *ngIf="plan.promotionalSubscriptionMonths > 0">Por</span>
                <span class="promotional-price">
                    R$ {{ plan.promotionalSubscriptionValue | currency: 'BRL':'symbol':'1.2-2':'pt-BR' }}
                </span>
            </div>
        </div>
        <p class="promo-details" *ngIf="plan.promotionalSubscriptionMonths > 0">Desconto válido por {{ plan.promotionalSubscriptionMonths }} meses.</p>
    </div>
</div>
<ng-template #noPlans>
    <p class="no-plans-message">Nenhum plano disponível no momento.</p>
</ng-template>
