<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget">
                    <div class="footer-logo">
                        <img src="assets/img/logo.png" style="height: 60px" alt="logo">
                    </div>
                    <p>Simplificamos sua rotina com uma ferramenta que otimiza a gestão de alunos, liberando tempo para o que realmente importa. Deixe a parte administrativa com a gente e foque no seu sucesso.</p>
<!--                    <div class="footer-link">-->
<!--                        <a routerLink="/">-->
<!--                            <img src="assets/img/google-play.png" alt="google image">-->
<!--                        </a>-->
<!--                        <a routerLink="/">-->
<!--                            <img src="assets/img/app-store.png" alt="google image">-->
<!--                        </a>-->
<!--                    </div>-->
                </div>
            </div>
<!--            <div class="col-lg-3 col-sm-6">-->
<!--                <div class="footer-widget pl-90">-->
<!--                    <h3>Company</h3>-->
<!--                    <ul>-->
<!--                        <li><a href="#"><i class="flaticon-right"></i> Advertise</a></li>-->
<!--                        <li><a href="#"><i class="flaticon-right"></i> Support</a></li>-->
<!--                        <li><a href="#"><i class="flaticon-right"></i> Marketing</a></li>-->
<!--                        <li><a href="#"><i class="flaticon-right"></i> Bonus</a> </li>-->
<!--                        <li><a href="#"><i class="flaticon-right"></i> Contact</a></li>-->
<!--                    </ul>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="col-lg-3 col-sm-6">-->
<!--                <div class="footer-widget pl-90">-->
<!--                    <h3>Quick Links</h3>-->
<!--                    <ul>-->
<!--                        <li><span (click)="onClick('home')"><i class="flaticon-right"></i> Home</span></li>-->
<!--                        <li><span (click)="onClick('home')"><i class="flaticon-right"></i> Features</span></li>-->
<!--                        <li><span (click)="onClick('home')"><i class="flaticon-right"></i> Screenshots</span></li>-->
<!--                        <li><span (click)="onClick('home')"><i class="flaticon-right"></i> Pricing</span></li>-->
<!--                        <li><span (click)="onClick('home')"><i class="flaticon-right"></i> Testimonial</span></li>-->
<!--                    </ul>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="col-lg-3 col-sm-6">-->
<!--                <div class="footer-widget pl-90">-->
<!--                    <h3>Social Pages</h3>-->
<!--                    <ul>-->
<!--                        <li><a href="#" target="_blank"><i class="flaticon-right"></i> Facebook</a></li>-->
<!--                        <li><a href="#" target="_blank"><i class="flaticon-right"></i> Twitter</a></li>-->
<!--                        <li><a href="#" target="_blank"><i class="flaticon-right"></i> Linkedin</a></li>-->
<!--                        <li><a href="#" target="_blank"><i class="flaticon-right"></i> Instagram</a></li>-->
<!--                        <li><a href="#" target="_blank"><i class="flaticon-right"></i> Pinterest</a> </li>-->
<!--                    </ul>-->
<!--                </div>-->
<!--            </div>-->
        </div>
    </div>
    <div class="copyright-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-4 lh-1">
<!--                    <img src="assets/img/visa.png" alt="visa card">-->
<!--                    <img src="assets/img/american-express.png" alt="american-express">-->
<!--                    <img src="assets/img/discover.png" alt="discover">-->
<!--                    <img src="assets/img/master-card.png" alt="master card">-->
                </div>
                <div class="col-lg-6 col-md-8 lh-1">
                    <p>© Classmap Serviços em Tecnologia Ltda</p>
                </div>
            </div>
        </div>
    </div>
</footer>
