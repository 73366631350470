<div id="services" class="service-style-two pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Oferecemos uma gestão completa</h2>
            <p>Em um único lugar, você terá a praticidade de cadastrar alunos, auxiliares e criar a grade de dias e horários de forma simples e rápida. Com essa plataforma, todo o processo se torna mais organizado e eficiente, economizando tempo e garantindo que todas as informações estejam centralizadas para facilitar a gestão.</p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="service-item">
                    <img src="assets/img/sass-landing/service/1.png" alt="icon">
                    <h3>Cadastro de auxiliar</h3>
                    <p>Todos os  auxiliares podem ser facilmente cadastrados na plataforma, permitindo que participem ativamente da gestão do dia a dia. Com essa funcionalidade, a distribuição de tarefas e o acompanhamento das atividades se tornam mais eficientes, oferecendo suporte crucial para o bom andamento das operações e facilitando a organização de processos.</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="service-item">
                    <img src="assets/img/sass-landing/service/4.png" alt="icon">
                    <h3>Cadastro de alunos</h3>
                    <p>Cadastrar alunos no sistema é muito simples e rápido! Basta importar a planilha de aniversários completa do KSIS, copiar e colar as informações no sistema. Em seguida, é só ajustar o dia e horário do aluno, e pronto! O processo é seguro, pois não há exposição de dados sensíveis; as informações são apenas de aniversários e estão em conformidade com as leis de proteção e tratamento de dados pessoais. Assim, garantimos a privacidade e a segurança de todas as informações.</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="service-item">
                    <img src="assets/img/sass-landing/service/3.png" alt="icon">
                    <h3>Gestão completa</h3>
                    <p>Em um único lugar, você terá a praticidade de cadastrar alunos, auxiliares e criar a grade de dias e horários de forma simples e rápida. Com essa plataforma, todo o processo se torna mais organizado e eficiente, economizando tempo e garantindo que todas as informações estejam centralizadas para facilitar a gestão.</p>
                </div>
            </div>
<!--            <div class="col-lg-4 col-sm-6">-->
<!--                <div class="service-item">-->
<!--                    <img src="assets/img/sass-landing/service/4.png" alt="icon">-->
<!--                    <h3>Client Managing</h3>-->
<!--                    <p>When it comes after the noun, the ar djective usually doesn't get a hyphen. So we say an easy to remember.</p>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="col-lg-4 col-sm-6">-->
<!--                <div class="service-item">-->
<!--                    <img src="assets/img/sass-landing/service/5.png" alt="icon">-->
<!--                    <h3>Data Collection</h3>-->
<!--                    <p>When it comes after the noun, the ar djective usually doesn't get a hyphen. So we say an easy to remember.</p>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="col-lg-4 col-sm-6">-->
<!--                <div class="service-item">-->
<!--                    <img src="assets/img/sass-landing/service/6.png" alt="icon">-->
<!--                    <h3>Secured System</h3>-->
<!--                    <p>When it comes after the noun, the ar djective usually doesn't get a hyphen. So we say an easy to remember.</p>-->
<!--                </div>-->
<!--            </div>-->
        </div>
    </div>
    <div class="service-shape">
        <img src="assets/img/map-four.png" alt="map shape">
        <img src="assets/img/sass-landing/shapes/3.png" alt="shape">
    </div>
</div>
