<div id="home" class="main-banner main-banner-two banner-bg-three">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="banner-text">
                    <h1>Gerencie o mapa de classe da sua unidade de uma forma fácil e rápida</h1>
                    <p>Uma unidade que utiliza o CLASSMAP para gerenciar dias e horários de aulas demonstra eficiência e inovação. A ferramenta otimiza a organização, proporcionando uma experiência ágil e clara para todos os envolvidos.
                        Investir no CLASSMAP é garantir mais eficiência, economia de tempo e foco na qualidade do ensino e aprendizado.</p>

                    <div class="banner-btn">
                        <a [href]="getUrlSignup()" target="_blank" rel="noopener noreferrer">Comece Sua Avaliação Gratuita</a>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <div class="banner-image-three">
        <img src="assets/img/sass-landing/classmap1.png" alt="laptop">
        <img src="assets/img/shape/home-shape-three.png" alt="shape">
    </div>
    <div class="banner-shape-two">
        <img src="assets/img/sass-landing/shapes/1.png" alt="shape">
        <img src="assets/img/sass-landing/shapes/1.png" alt="shape">
    </div>
</div>
<div class="video-popup">
    <!-- <ngx-smart-modal #popupOne [identifier]="'popupOne'">
        <iframe src="https://www.youtube.com/embed/WQ119jAN4Xo" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </ngx-smart-modal> -->
</div>
