import { Component, OnInit } from '@angular/core';
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-sass-two-banner',
  templateUrl: './sass-two-banner.component.html',
  styleUrls: ['./sass-two-banner.component.scss']
})
export class SassTwoBannerComponent implements OnInit {

    constructor() {}

    ngOnInit(): void {}

    getUrlSignup(){
        return `${environment.url}?redirect=signup`;
    }
}
